<template>
<div class="container">
    <div class="row archive">
        <div class="col-12">
            <h2 v-if="archive.title !== null">{{archive.title}}</h2>
            <p v-html="archive.description"></p>
            <a v-if="archive.file != null && archive.file.length > 0" v-bind:href="'https://assets.pharmanavi.de/assets/uploads/Streaming/' + archive.file" class="btn btn-primary mb10 download-link" v-bind:download="archive.title" target="_blank">
                Übungszettel
                <i class="fa fa-download"></i>
            </a>
            <a v-if="archive.presentation != null && archive.presentation.length > 0" v-bind:href="'https://assets.pharmanavi.de/assets/uploads/Streaming/' + archive.presentation" class="btn btn-primary mb10 download-link" v-bind:download="archive.title + '_presentation'" target="_blank">
                Präsentation
                <i class="fa fa-download"></i>
            </a>
            <br/><br/>
            <div v-if="archive.vimeo_video_id != null" class="video-container mb30" style="position: relative; padding-bottom: 56.25%; padding-top: 30px; height: 0; overflow: hidden;">
                <iframe v-bind:src="'https://player.vimeo.com/video/' + archive.vimeo_video_id" width="640" height="399" frameborder="0"
                        allow="autoplay; fullscreen" allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" showinfo="false"></iframe>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {API_URL} from "@/conf.js";
import authHeader from "@/auth.header.js";

export default {
    name: "Archive",
    data() {
        return {
            archive: {}
        }
    },
    beforeMount() {
        this.loadArchive();
    },
    mounted() {
        let content = document.getElementById("app");
        console.log(content);
        // Now scan for media
        window._paq.push(['MediaAnalytics::scanForMedia', content]);
    },
    methods: {
        loadArchive() {
            axios.get(`${API_URL}archives/${this.$route.params.id}/show`, {'headers': authHeader()})
                .then(response => {
                    if (response.status === 200) {
                        this.archive = response.data.archive;
                        this.fetchFromVimeoApi(this.archive.vimeo_video_id);
                        this.$emit("navigation-sub-heading", response.data.archive.title);
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        fetchFromVimeoApi(videoId) {
            var urlEncoded = encodeURIComponent('https://player.vimeo.com/video/' + videoId)
            axios.get('https://vimeo.com/api/oembed.json?url=' + urlEncoded)
                .then(response => {
                   console.log(response.data);
                })
                .catch(error => {
                    console.log(error)
                });
        }
    }
}
</script>

<style scoped>

</style>
